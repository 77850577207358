.formStyle {
  margin: 20px auto;
  padding: 20px;
  border-radius: 9px;
  box-shadow: 0px 0px 12px -3px #000000;
  display: flex;
  justify-content: space-between;
}
.submitButton {
  margin-left: 20px;
}

.goal__add-Form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.goal__input {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 1rem !important;
  grid-gap: 0.5rem;
}

.goal__color {
  width: 100% !important;
  margin-left: 0.5rem !important;
  margin-bottom: 1rem !important;
}

@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
  .formStyle {
    width: 100%;
  }
}
@media (max-width: 900px) {
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}

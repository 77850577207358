/* .profile__name {
  display: flex;
  background-color: red;
  justify-content: center;
  padding: 0.5rem;
  width: 100%;
} */

.profile__img,
.profile__name {
  text-align: center;
}
.profile__back {
  position: absolute;
  width: 100%;
  height: 30%;
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    var(--first-color-lighter) 0%,
    transparent 100%
  );
}

.mobile__top {
  display: flex;
  /* margin-left: 0.5rem;
  margin-right: 0.5rem; */
  width: auto;
  /* background-color: var(--body-color); */
}

.mobile__title {
  display: flex;
  font-size: var(--h2-font-size);
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-right: 3rem;
}
/*Buttons */
.button__back {
  font-size: 1rem;
  font-size: var(--font-semi-bold);
  color: var(--title-color) !important;
}
.button__backWhite {
  color: #fff !important;
}
.button__right {
  font-size: 1.5rem;
  color: var(--title-color);
}
.MuiTabs-indicator {
  background-color: #fff !important;
}
@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 900px) {
  .scroll-top {
    width: 100%;
    /* padding-right: 1rem; */
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(8px);
  }
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}

.dashboard__container {
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  display: flex;
  align-items: center;
  justify-content: center;
}
.test {
  position: absolute;
  width: 100%;
  height: 15rem;
  z-index: -1;
  top: 0;
  left: 0;

  /* background: linear-gradient(
    90deg,
    rgba(63, 94, 251, 1) 0%,
    rgba(252, 70, 107, 1) 100%
  ); */
  /* background: linear-gradient(
    180deg,
    rgba(63, 94, 251, 1) 0,
    rgba(252, 70, 107, 1) 80%,
    transparent
  ); */
}

.text-white {
  color: white;
}

/*header*/
.dashboard__header {
  grid-template-columns: 2fr auto;
}
.dashboard__avatar {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  font-size: var(--font-medium);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.dashboard__level {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  font-size: var(--small-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}
.dashboard__points {
  font-size: var(--small-font-size);
}
.progression__container {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: var(--smaller-font-size);
}

.progression__dates {
  display: flex;
  justify-content: space-between;
}

.progression__indicator {
  display: flex;
  justify-content: space-between;
}

.progression__bar {
  height: 1rem;
}

.progression__percentage {
  height: 1rem;
}
.summary__rows {
  display: flex;
  grid-gap: 0.5rem;
  overflow-x: auto;
  justify-content: start;
  padding: 1rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.summary__rows::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.summary__rows {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.summary__content {
  position: relative;
  background-color: var(--input-color);
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  display: flex;
  justify-content: center;
  min-width: 130px;
  max-width: 200px;
}

.summary__title {
  font-size: var(--h4-font-size);
  margin-bottom: var(--mb-0-5);
  font-weight: var(--font-medium);
  color: var(--text-color);
}

/* activity */
.activity__container {
  margin-top: var(--mb-1);
}
.activity__content {
  background-color: var(--container-color);
  padding: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  margin-top: var(--mb-0-5);
}
.activity__subtitle {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
}

.activity__data {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.activity__text {
  font-size: var(--font-medium);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}
.activity__icon {
  display: block;
  font-size: 1rem;
  color: var(--text-color-light);
  cursor: pointer;
}
.activity__bar,
.activity__percentage {
  height: 1px;
  border-radius: 0.25rem;
}
.activity__bar {
  background-color: var(--text-color-light);
}

/* For small devices */
@media screen and (max-width: 350px) {
}
/* For medium devices */

@media screen and (min-width: 568px) {
  .summary__rows {
    grid-gap: 1rem;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
}
/* For large devices */
@media screen and (min-width: 1024px) {
}
.CircularProgressbar-path {
  border-radius: 5rem !important;
}
.listtoday {
  max-height: 200px;
  overflow-y: auto;
}

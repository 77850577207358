.formStyle {
  margin: 20px auto;
  padding: 20px;
  border-radius: 9px;
  box-shadow: 0px 0px 12px -3px #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  height: 300px;
}
.todo__add-Form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.todo__input {
  margin-bottom: 1rem !important;
}
.todos__button {
  background-color: var(--first-color-alt);
}
@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
  .formStyle {
    width: 100%;
  }
}
@media (max-width: 900px) {
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}

.formStyle {
  margin: 20px auto;
  padding: 20px;
  border-radius: 9px;
  box-shadow: 0px 0px 12px -3px #000000;
  display: flex;
  justify-content: space-between;
}
.submitButton {
  margin-left: 20px;
}

.sameTrimester {
  display: block !important;
}

.notSameTrimester {
  display: none !important;
}

.activeGoal {
  display: block !important;
}

.notActiveGoal {
  display: none !important;
}

.trimesterStyle {
  margin: 20px auto;
  padding: 20px;
  border: 2px solid #bdbdbd;
  border-radius: 9px;
  display: flex;
  justify-content: space-between;
}
.moreStyle {
  color: #8f8f8f;
}
.isCompleted {
  color: green;
}
.isActive {
  color: red;
}
.notActive {
  color: red;
}
.checked {
  text-decoration: line-through;
}
